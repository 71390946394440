export default {
    Auth: {
        Title: {
            SignIn: '用户登录',
            SignUp: '用户注册',
            ModifyPassword: '修改密码',
            BaseInfo: '基本信息',
        },
        Form: {
            Tab1: '账号密码登录',
            Tab2: '短信验证登录',
            Username: '用户名',
            UserInfo: '账号信息',
            UserPlaceholder: '至少4个字符',
            UserID: '用户ID',
            RegisteredAt: '注册时间',
            Password: '密码',
            PasswordPlaceholder: '至少6个字符',
            ConfirmPassword: '再次输入密码',
            Contact: '联系人',
            CorpInfo: '企业信息',
            CorpName: '公司名',
            CorpID: '企业ID',
            Mobile: '手机号',
            Captcha: '图形验证码',
            SMSCaptcha: '短信验证码',
            OldPassword: '旧密码',
            NewPassword: '新密码',
            ConfirmNewPassword: '再次输入新密码',
            BtnX: '获取短信验证码',
            BtnXDisabled: 's后可重新获取',
            BtnSignIn: '登录',
            BtnSignUp: '确认注册',
            BtnModify: '确认修改',
        },
        Link: {
            PrToSignIn: '已有账号?',
            ToSignIn: '立即登录',
            PrToSignUp: '还没有账号？',
            ToSignUp: '创建账号',
            PrAgreement: '我已阅读并同意',
            Agreement: '《麦客网软件使用及服务协议》',
        },
        Hint: {
            SignInSuccess: '登录成功',
            SignUpSuccess: '注册成功',
            S1: '两次输入密码不一致',
            S2: '请输入正确的手机号',
            S3: '请先同意服务条款',
            S4: '请输入图形验证码',
            S5: '验证码已发送',
        },
    }
}